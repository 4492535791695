import { Controller } from 'stimulus';
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = ['description', 'descriptionTruncated', 'descriptionToggle']
  static values = { expanded: Boolean, }

  connect() {
    this.useTruncatedDescription = this.element.getAttribute('data-use-truncated-description') === 'true';

    // Short-circuit if we're not using the truncated description
    if (!this.useTruncatedDescription) return;

    // Set initial state properly without toggling
    this.expandedValue = false; // Set expanded state to false initially
    this.updateButton(); // Update the button based on the initial state
    this.handleResize(); // Set initial state based on screen size

    this.debouncedResize = debounce(this.handleResize.bind(this), 100);
    window.addEventListener('resize', this.debouncedResize);
  }

  disconnect() {
    // Short-circuit if we're not using the truncated description
    if (!this.useTruncatedDescription) return;

    window.removeEventListener('resize', this.debouncedResize);
  }

  toggleDescription() {
    this.expandedValue = !this.expandedValue;

    if (this.expandedValue) {
      this.expandSection();
    } else {
      this.collapseSection();
    }

    this.updateButton();
  }

  collapseSection() {
    // Set height immediately to enable transition, then collapse
    const element = this.descriptionTarget;
    element.style.height = `${element.scrollHeight}px`;
    requestAnimationFrame(() => {
      element.style.height = '0px';
    });

    // Properly toggle classes for hiding/showing content
    element.classList.add('is-hidden');
    this.descriptionTruncatedTarget.classList.remove('is-hidden');
  }

  expandSection() {
    const element = this.descriptionTarget;
    // Remove the class first to measure the correct scrollHeight
    element.classList.remove('is-hidden');
    element.style.height = `${element.scrollHeight}px`;

    element.addEventListener('transitionend', () => {
      // Reset height only if it's still expanded
      if (this.expandedValue) {
        element.style.height = null;
      }
    }, { once: true });

    // Ensure the truncated description is hidden when the full description is shown
    this.descriptionTruncatedTarget.classList.add('is-hidden');
  }

  updateButton() {
    if (!this.hasDescriptionToggleTarget) return;
    const isExpanded = this.expandedValue;
    this.descriptionToggleTarget.setAttribute('aria-expanded', isExpanded.toString());
    this.descriptionToggleTarget.textContent = isExpanded ? 'View less' : 'View more';
  }

  handleResize() {
    if (!this.hasDescriptionToggleTarget) return;
    // If the current screen size is larger than 767px
    if (window.innerWidth > 767) {
      // Always show the full description, and hide the toggle button
      this.expandedValue = true;
      this.expandSection();
      this.descriptionToggleTarget.classList.add('is-hidden');
    } else {
      // Hide the full description, and show the toggle button
      this.expandedValue = false;
      this.collapseSection();
      this.descriptionToggleTarget.classList.remove('is-hidden');
    }
    this.updateButton();
  }
}
